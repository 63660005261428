.contact {

    .contact-inner {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 900px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .email-link {
            color: var(--green);
            text-decoration: none;
            border: 1px solid var(--green);
            padding: 10px 14px 10px 14px;
            border-radius: 6px;
        }

        .email-link:hover {
            color: var(--offwhite-1);
            border: 1px solid var(--offwhite-1);
        }
    }
}