@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

@media (min-width: 600px) {
    html {
        font-size: 12pt;
    }

    h1 {
        font-size: 5rem;
    }
}

@media (max-width: 599px) {
    html {
        font-size: 11pt;
    }

    h1 {
        font-size: 3rem;
    }
}

h2 {
    font-size: 2rem;
    margin-bottom: 6px;
}

body {
    background-color: var(--navy-1);
    color: var(--offwhite-1);
}

strong {
    color: var(--highlight);
    font-weight: 600;
}

:root {
    --offwhite-1: #a4fce7;
    --navy-1: #0a1629;
    --navy-shadow: #050b14;
    --mono-font: "Space Mono";
    --gold: #D4AF37;
    --green: #99ffcc;
    --mint: #3EB489;
    --highlight: var(--mint);
}