.desktop-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    position: sticky;
    top: 0;
    transition: 500ms all cubic-bezier(0.4, 0, 0.2, 1);
    background-color: var(--navy-1);
    z-index: 2;

    .name-wrapper {

        p,
        span {
            font-weight: 700;
            font-size: 15pt;
            font-family: var(--mono-font);
            color: var(--gold);
        }
    }

    .nav-links {
        list-style-type: none;
        display: flex;
        flex-direction: row;

        a {
            font-family: var(--mono-font);
            font-weight: 500;
            font-size: 11pt;
            margin-left: 20px;
            color: var(--gold);
            text-decoration: none;
            transition: 0.3s all ease-in-out;
        }

        a:hover {
            color: var(--offwhite);
        }
    }
}

.hide {
    top: -80px;
}

.shadow {
    box-shadow: 0 10px 20px -10px var(--navy-shadow);
}

@media (max-width: 679px) {
    .desktop-navbar {
        display: None;
    }
}