.mobile-navbar {

    background-color: var(--dark-blue);
    display: flex;
    flex-direction: column;
    position: sticky;

    .mobile-navbar-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 1.5em;
        padding: 1em;
    }

    .name-wrapper {

        p,
        span {
            font-weight: 700;
            font-size: 15pt;
            font-family: var(--mono-font);
            color: var(--gold);
        }
    }

    .burger-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
    }

    .burger-bar {
        width: 1.5em;
        height: 0.25em;
        background-color: white;
        border-radius: 0.5em;
    }

    .menu {
        width: 100%;
        position: absolute;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        background-color: var(--navy-1);
        box-shadow: 0 10px 20px -10px var(--navy-shadow);
    }

    .hidden {
        display: none;
    }

    .visible {
        display: inherit;
        position: sticky;
    }

    .burger-bar.clicked:nth-child(1) {
        transform: rotate(45deg) translate(0.5em, 0.375em);
        transition: ease-out 0.2s;
    }

    .burger-bar.clicked:nth-child(2) {
        transform: scale(0.0);
        transition: ease-out 0.2s;
    }

    .burger-bar.clicked:nth-child(3) {
        transform: rotate(135deg) translate(-0.5em, 0.375em);
        transition: ease-out 0.2s;
    }

    .burger-bar.unclicked {
        transform: rotate(0) translate(0);
        transition: ease-out 0.2s;
    }
    
    a {
        font-family: var(--mono-font);
        font-weight: 500;
        font-size: 11pt;
        margin-left: 20px;
        padding: 1.1em;
        color: var(--gold);
        text-decoration: none;
        transition: 0.3s all ease-in-out;
        user-select: none;
    }

    a:hover {
        color: var(--offwhite);
    }
}

@media (min-width: 680px) {
    .mobile-navbar {
        display: None;
    }
}