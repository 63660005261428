.projects {
    height: 100vh;

    .projects-inner {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 900px;
        display: flex;
        flex-direction: column;

        .project {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            margin-bottom: 20px;
            flex-wrap: wrap;

            p {
                margin-top: 0.5rem;
                position: relative;
                z-index: 10;
                border-radius: 6px;
            }

            a {
                color: var(--gold);
                font-family: var(--mono-font);
                text-decoration: none;
                font-weight: 600;
                transition: 0.2s all ease-in-out;
                display: flex;
                align-items: center;
                width: fit-content;
            }

            .arrow {
                padding-left: 4px;
                transition: 0.2s padding ease-in-out;
            }
            
            a:hover {
                color: var(--offwhite-1);

                .arrow {
                    padding-left: 12px;
                }
            }

            .info {
                margin-right: 20px;
                flex: 1 1 300px;
                line-height: 1.4rem;
                font-size: 1rem;

                .project-tools {
                    padding: 10px;
                }

                ul {
                    list-style-type: none;
                    font-family: var(--mono-font);
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    li {
                        color: var(--green);
                        margin: 0.4rem;
                        font-weight: 500;
                        font-size: 0.9rem;
                    }
                }
            }
        }
        
        .project-img-wrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: 1 1 400px;
            padding-top: 1.2rem;
        }

        .test {
            flex: 1 1 400px;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            background-size: cover;
            color: white;
            border-radius: 6px;
        }

        img {
            object-fit: contain;
            transition: 0.2s all ease-in-out;
            cursor: pointer;
            opacity: 0.7;
            width: 100%;
            border-radius: 6px;
        }

        img:hover {
            opacity: 1.0;
        }
    }
}