.experience {    
    min-height: 100vh;

    .experience-inner {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 900px;
        display: flex;
        flex-direction: column;

        .description {
            margin-top: 20px;
            margin-bottom: 20px;

            h3 {
                color: var(--green);
                margin-bottom: 5px;
            }
            p {
                line-height: 1.4rem;
                font-size: 1rem;
                margin-bottom: 10px;
            }

        }
        .description:last-child {
            margin-bottom: 100px;
        }

        a, span {
            color: var(--gold);
            text-decoration: none;
            transition: 0.2s all ease-in-out;
        }
        a:hover {
            color: var(--offwhite);
        }
        .arrow {
            margin-left: 10px;
            margin-right: 10px;
        }
        ul {
            list-style-type: none;
            li {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
            }
        }
    }
}