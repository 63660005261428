.about {
    min-height: 100vh;

    .about-inner {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 900px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;


        .info-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .info-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .info {
            flex: 1 1 400px;
            margin-top: 20px;
        }
    }

    p {
        margin-bottom: 20px;
        line-height: 1.4rem;
        font-size: 1rem;
    }

    .technologies-label {
        margin-bottom: 4px;
    }

    .profile-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 2rem;
        flex: 1 1 400px;
    }

    .profile-img {
        width: 300px;
        max-width: 60vw;
        border-radius: 6px;
        margin-bottom: 100px;
    }

    .icons-wrapper {
        display: flex;
        flex-wrap: wrap;

        p {
            font-family: var(--mono-font);
            display: flex;
            align-items: center;
            height: auto;
            margin: 8px 8px 16px 8px;
            color: var(--green);

            .icon {
                padding-right: 8px;

            }
        }
    }
}