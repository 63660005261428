.hero {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .hero-inner {
        margin-left: auto;
        margin-right: auto;
        width: 80%;

        hr {
            width: 100%;
            max-width: 500px;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            margin-top: 20px;
            width: 400px;
            max-width: 80%;
            font-size: calc(12px + 0.5vw);
        }

        .icon-wrapper {
            margin-top: 20px;
            margin-left: 10px;

            .icon {
                margin-right: 20px;
                color: var(--gold);
                font-size: 1.2rem;
                cursor: pointer;
                transition: 0.2s all ease-in-out;
            }

            .icon:hover {
                color: var(--offwhite-1);
            }
        }

        ;
    }
}